form {
	.form-title {
		font-weight: 400;
		color: #000000;
		font-size: 1.26rem;
		text-transform: none;
	}

	section {
		padding: 1rem;
		color: #343a40;
		border: none;
		border-radius: 4px;
		background: #ffffff;
		box-shadow: 0 1px 2px 2px rgba(0, 0, 0, 0.1);

		& + section {
			margin: 1.25rem 0;
		}

		h5.section-title {
			font-size: 1.26rem;
			color: #565C65;
			font-weight: 600;
			text-transform: none;
		}

		h6.section-subtitle {
			font-size: 1.1rem;
			color: #707070;
			font-weight: 500;
			text-transform: none;
		}
	}

	fieldset {
		padding: 1rem;
		color: #343a40;
		border: none;
		border: 1px solid #dddddd;
		border-radius: 4px;

		& + fieldset {
			margin: 1rem 0;
		}

		legend {
			font-size: 1.25rem;
			display: block;
			width: auto;
			padding: 0 1.5rem;
			color: #343a40;
			font-weight: 500;
		}
	}

	.form-info {
		font-weight: 500;
		font-size: 1rem;
		display: flex;
		vertical-align: center;

		i {
			margin-right: 0.5rem;
		}
	}

	button.btn-next {
		box-shadow: none;
		background-color: #1351b4;
		color: #ffffff;
		transition: filter 0.2s;
		border-radius: 25px;

		&:hover {
			filter: brightness(0.9);
		}
	}

	button.btn-cancel,
	button.btn-add {
		box-shadow: none;
		background-color: transparent;
		color: #1351b4;
		transition: filter 0.2s;
		border-radius: 25px;

		&:hover {
			background-color: #ffffff;
			filter: brightness(0.9);
		}
	}

	button.btn-add {
		display: flex;
		align-items: center;
	}

	button[disabled] {
		cursor: not-allowed;
		opacity: 0.9;
	}
	button.btn-remove-usuario {
		background: none;
		border: none;
		color: #dddddd;
		position: relative;
		// border: none;
		// padding: 0;

		i {
			position: absolute;
			margin-top: -14px;
			transform: translate(-15%, -15%);
			//background-color: none;
		}
	}

	.required-icon {
		color: #ff0000;
	}
}
