.mat-horizontal-stepper-header-container {
    .mat-step-header {
        .mat-step-icon {
            background-color: #f7f9fa;
            width: 35px;
            height: 35px;
            border: 1px solid #1351b4;
        
            .mat-step-icon-content{
                 color:#1351b4; 
                font-size: 25px;
                font-weight: bolder !important;
            }

         
        } 
    
        .mat-step-icon-selected{
            background-color: #0c326f;
            color: #f7f9fa;

            .mat-step-icon-content{
                color:#f7f9fa; 
               font-size: 25px;
               font-weight: bolder !important;
           }
        }

      
    } 
    // .mat-stepper-horizontal-line{
    //  border: 1px solid #1351b4 !important;
    
    // } 

       
} 

.mat-horizontal-stepper-header::before, .mat-horizontal-stepper-header::after, .mat-stepper-horizontal-line {
    border-top-color: #1321b4 !important;
}