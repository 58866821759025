$fa-font-path: '~@fortawesome/fontawesome-free/webfonts';
@import "~@fortawesome/fontawesome-free/scss/fontawesome.scss";
@import "~@fortawesome/fontawesome-free/scss/solid.scss";
@import "~@fortawesome/fontawesome-free/scss/brands.scss";

@import "~@angular/material/prebuilt-themes/indigo-pink.css";
@import "core/variables";
@import "core/mixins";
@import "~bootstrap/scss/bootstrap";
// Core Components
@import "core/buttons";
@import "core/checkboxes";
@import "core/radios";
@import "core/forms";
@import "core/input-group";
@import "core/images";
@import "core/navbar";
@import "core/alerts";
@import "core/type";
@import "core/tabs";
@import "core/tooltip";
@import "core/popover";
@import "core/dropdown";
@import "core/togglebutton";
@import "core/ripples";
@import "core/footers";
@import "core/sidebar-and-main-panel";
@import "core/fixed-plugin";
@import "core/tables";
@import "core/misc";
@import "core/cards";
@import "core/cards/card-stats";
@import "core/cards/card-profile";
@import "core/cards/card-plain";
//plugin scss
@import "core/plugins/animate";
@import "core/plugins/chartist";
@import "core/plugins/perfect-scrollbar";
@import "core/responsive";
@import "core/angular-modal.scss";
//- Estilo dos formulários
@import "core/form-credenciamento";
@import "core/stepper";

body {
    background-color: #F7F9FA;
}

* {
    font-family: 'Rawline', sans-serif;
}

.alto-contraste{
   
    .row{
        background-color: #000000;
        color: #ffffff;
    }

    a, button {
        text-decoration: underline #ffffff !important;
        background-color: #000000 !important;
    }

    
    button:not(.br-item, i){
       border: 1px solid #ffffff;
    }

    button:not(:disabled), [type=button]:not(:disabled), [type=reset]:not(:disabled), [type=submit]:not(:disabled){
        color: #ffffff;
    }
    
    
    p{
        color: #ffffff;
    }

    .br-content-container{
        background-color: #000000 !important;
        .form-title{
            color: #ffffff !important;
        }
        
    }

    section{
        background-color: #000000 !important;
        border: 1px solid #ffffff;
        box-shadow: 1px 1px 1px 2px #494747;
        label{
            color: #ffffff !important;
        }
        .section-title{
            color:#ffffff !important;
        }
        .mat-table{
            & *{
                background-color: #000000;
                color: #ffffff;
            }
        }

        table *{
            background-color: #000000;
            color: #ffffff;
        }

    }
  
    // .mat-form-field-label-wrapper {
    //     display: none;
    // }

    .br-menu{
        background-color: #000000 !important;
        color: #ffffff !important;
    }


    // mat-checkbox {
    //     .mat-checkbox-layout{
    //         color: #000000 !important;
    //     }
    //     .mat-checkbox-inner-container{
    //         color: #000000 !important;
    //     }
    // }

    .mat-input-element:disabled{
        
         color: #ffffff !important;
    
    }

    h5{
        color: #ffffff !important;
    }



    app-navbar *{
        background-color: #000000 !important;
        color: #ffffff
    }
    
    .br-header{
        background-color: #000000 !important;
        border-bottom: 1px solid #ffffff;
    }


    app-dropdown {
        .mat-form-field{
            .mat-form-field-wrapper{
            
            .mat-form-field-flex{
                    & * :not(.mat-select-arrow){
                        color: transparent;
                    }
                }
            }

            .mat-form-field-infix{
                
                .mat-select-empty{
                    background-color: #ffffff;
        
                }
                .mat-select-value{
                    background-color: #ffffff;
                    //color: #000000;
                    padding: 10px 0 10px 10px;
                    
                    .mat-select-value-text{
                        .mat-select-min-line{
                            background-color: #ffffff;
                            color: #000000;
                            padding: 10px 0 10px 10px;
                            

                        }
                    }

                    .mat-select-arrow-wrapper{
                        .mat-select-arrow{
                             color: #000000;
                        }
                       
                    }
                }
                  
                   
            }
        }      

    }    
                

    input, textarea:not(:disabled){
        background-color: #ffffff !important;
        color: #000000 !important;
        padding: 10px 0 10px 10px;
    }

    

    .mat-input-element:disabled{
        background-color: #ffffff !important;
        color: #000000 !important;
    }

    


    app-input-cep, app-input-cnpj, app-input-text, app-input-text, app-input-cpf, app-input-cpf, app-input-telefone, 
     app-input-time, app-input-titulo-eleitor, app-input-titulo-eleitor, app-input-titulo-eleitor {
        .mat-form-field{
            .mat-form-field-wrapper{
            .mat-form-field-flex{
                    & *{
                        color: transparent;
                    }
                }
            }
       }
    }

    app-input-date {
        co.mat-form-field{
            .mat-form-field-wrapper{
            .mat-form-field-flex{
                    & *{
                        color: transparent;
                    }
                }
            }
       }

    }


    app-resultado-analise-tecnica{
        .mat-expansion-panel{  
            .mat-expansion-panel-content{
                
                .mat-form-field{
                    .mat-form-field-wrapper{
                        .mat-form-field-flex{
                            .mat-form-field-outline{
                                & *{
                                    color: transparent !important;
                                }
                            }
                        }
                        .mat-form-field-infix{
                            .mat-form-field-label-wrapper{
                                label {
                                    mat-label{
                                        color: #000000;
                                    }
                                    
                                }
                                
                            }
                        }
                    }
                }
                
            }
        }
    }

    &.mat-form-field-outline{
        color: #ffffff !important;
    }

    app-select-chiplist{
        .mat-form-field{
            .mat-form-field-wrapper{
            
            .mat-form-field-flex{
                    & * :not(.mat-select-arrow){
                        color: transparent;
                    }
                }
            }

            .mat-form-field-infix{
                
                .mat-select-empty{
                    background-color: #ffffff;
                }    

                .mat-select-value{
                    background-color: #ffffff;
                    //color: #000000;
                    padding: 10px 0 10px 10px;
                }
            }
        }       
    }

    app-ds-input-upload {
        .arquivo-download{
            color: #000000;
        }

        .br-upload{
            .upload-list{
                span{
                    color: #000000;
                }
            }
         }
    }

    .br-upload {
        .arquivo-download{
            color: #000000;
        }
        &.upload-button{
           
            color: #ffffff;
            
        }
    }

    mat-checkbox {
        .mat-checkbox-layout{
            .mat-checkbox-inner-container{
               
                background-color: #ffffff;
                color: #000000;
               
            }
       }
  
    }

    .mat-form-field-label-wrapper{
        display: none;
    }
    
    mat-expansion-panel {
        color: #ffffff !important;
        background-color: #000000 !important;
        border: 1px solid #ffffff;
    }
    mat-expansion-panel-header {
        color: #ffffff !important;
        background-color: #000000 !important;
    }

    app-tabela *{
        background-color: #000000 !important;
        color: #ffffff !important;
        
    }
    
     .br-menu{  
         
        .sidebar-wrapper{
            background-color: #000000 !important; 
            border: 1px solid #ffffff !important;
            

            .nav{
                background-color: #000000;
            }

            .nav-item {
                border-bottom: 1px solid #dddddd;
                .nav-link{
                    p{
                        color: #ffffff !important;
                    }
                    
                }
        
                &.active .nav-link
                {

                    background-color:  #ffffff !important;
                   // color: #000000 !important;
                    span, i, p{
                            color: #000000 !important;
                            text-decoration: none;
                    }

                    
                }

            }

            .collapse {
                .nav-link{
                    color: #000000 !important;
                }
        
                .nav-item :hover{
                    background-color: #ffffff !important;
                    color: #000000 !important;
                }
            }
        
        }
    }
  
        
    app-modal-buscar-ar *{
        background-color: #000000 !important;
        color: #ffffff !important;

        .mat-form-field{
            .mat-form-field-wrapper{
                .mat-form-field-flex{
                    .mat-form-field-outline{
                        & *{
                            color: transparent !important;
                        }
                    }
                }
                .mat-form-field-infix{
                    input{
                        background-color: #ffffff !important;
                        color: #000000 !important;
                        padding: 10px 0 10px 10px;
                    }
                    .mat-form-field-label-wrapper{
                        label {
                            mat-label{
                                color: #000000;
                            }
                            
                        }
                        
                    }
                }
            }
        }
    }

    .mat-step-label {
        & *{
            color: #ffffff !important;
        }
    }

    app-solicitacao{
        .title{
            color: #ffffff;
        }
        .mat-horizontal-stepper-header-container {
            .mat-step-header {
                .mat-step-icon {
                    background-color: #000000;
                    border: 1px solid #ffffff;
                
                    .mat-step-icon-content{
                        color:#ffffff; 
                    }
                }

                .mat-step-icon-selected{
                    background-color: #837f7f !important;
                    color:#000000 !important; 
        
                    .mat-step-icon-content{
                        color:#000000 !important; 
                    }
                }
        
                
            } 

        
        } 
        
    }

    .mat-horizontal-stepper-header::before, .mat-horizontal-stepper-header::after, .mat-stepper-horizontal-line {
        border-top-color: #ffffff !important;
    }

    .title {
        & *{
            color: #ffffff !important;
        }
        
    }

    i {
        color: #ffffff !important;
    }

    .br-pagination{
        
       & *{
            color: #ffffff !important;
        }
    }

    span{
        color: #ffffff;
    }

    super-tabela {
        .mat-table{
            border: 1px solid #ffffff;
            & *{
                background-color: #000000;
                color: #ffffff;
            }
        }
        
       

    }

    thead{
        tbody{
            border: 1px solid #ffffff !important;
        }
        border: 1px solid #ffffff !important;
        tr {
           th {
                 color: #ffffff !important;
            }
           
        }
        
    }

   

    .mat-datepicker-toggle-default-icon{
        color: #ffffff !important;
    }
}

.fonte-dislexia{
    *:not(i, span) {
        font-family: 'Open-Dyslexic', sans-serif; 
    }         
}

 
